import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../redeem-voucher/RedeemVoucher.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../../context/ToastContext";
import { useDispatch, useSelector } from "react-redux";
import { getDayLabel, renderSpeed } from "../../utils/reuse";
import { getTopUpPrepaidBundle } from "../../services/api";

const RedeemVoucherBundleDetails = ({ bundleData, setLoading, setTopupExistingEsim, setvoucherTopUpData, createOrTopupEsim }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { showToast, labels, redeemVoucher } = useToast();
    const dispatch = useDispatch();
    const isAuth = useSelector(state => state.auth.isLoggedIn);
    const userData = useSelector((state) => state.auth.sessionData);
    const cartData = useSelector((state) => state.cart.catalogCart);
    const headers = useMemo(() => {
        return { sessionid: userData.sessionId };
    }, [userData.sessionId]);
    const [topup, setTopup] = useState(false);

    const topupExistingEsimHandler = useCallback(() => {
        if (isAuth) {
            setLoading(true);
            const getTopUpData = (response) => {
                if (response.result === "SUCCESS") {
                    setLoading(false);
                    setTopup(false);
                    if (response?.data.length > 0) {
                        setTopupExistingEsim(true);
                        setvoucherTopUpData(response.data);
                    } else {
                        showToast("error", redeemVoucher.VOUC013);
                    }
                } else {
                    setLoading(false);
                    setTopup(false);
                    const error = response.error;
                    showToast(error.severity, error.errorMsg);
                }
            }
            const bundle = cartData?.name;
            getTopUpPrepaidBundle(bundle, headers, dispatch, getTopUpData);
        } else {
            navigate("#login");
        }
    }, [cartData?.name, dispatch, headers, isAuth, navigate, redeemVoucher.VOUC013, setLoading, setTopupExistingEsim, setvoucherTopUpData, showToast])

    useEffect(() => {
        if (isAuth && location.hash === "#topup" && topup) {
            topupExistingEsimHandler()
        }
    }, [isAuth, location.hash, topup, topupExistingEsimHandler])

    return (
        <>
            <div className="vouchers-box bundle-details-section">
                <div className="data-item-content">
                    <div className="flex align-items-center justify-content-between title-description">
                        <h2>{labels.LBL0016}</h2>
                        <h2><span>{bundleData?.description?.split("(")[0].trim() || '-'}</span></h2>
                    </div>
                    <table className="w-12">
                        <tbody>
                            <tr>
                                <td>{labels.LBL0024}</td>
                                <td className="text-right">{bundleData?.dataAmountForDisplay || '-'}</td>
                            </tr>
                            <tr>
                                <td>{labels.LBL0025}</td>
                                <td className="text-right">{bundleData?.duration}{getDayLabel(bundleData?.duration, labels)}</td>
                            </tr>
                            <tr>
                                <td>{labels.LBL0026}</td>
                                <td className="text-right">{bundleData?.displaySpeed || '-'}</td>
                            </tr>
                            <tr>
                                <td>{labels.LBL0027}</td>
                                <td className="text-right">{bundleData?.roamingCountries || '-'}</td>
                            </tr>
                            <tr>
                                <td>{labels.LBL0028}</td>
                                <td className="text-right">
                                    <span class="networkData">
                                        {bundleData?.networks?.map(b => {
                                            return (
                                                <span class="catalogNetworks">{b?.name} ({renderSpeed(b?.speeds)})</span>
                                            )
                                        })}
                                    </span>
                                </td>
                            </tr>
                            <tr className="flex-column">
                                <div className="grid align-items-center">
                                    <div className="col-12 md:col-6">
                                        <div className="box" onClick={() => createOrTopupEsim()}>
                                            <div className="card flex align-items-center gap-3">
                                                <div className="icon">
                                                    <i className="pi pi-plus"></i>
                                                </div>
                                                <div className="text">
                                                    <h2>{redeemVoucher.VOUC008} <br /> {redeemVoucher.VOUC009}</h2>
                                                </div>
                                            </div>
                                            <i className="pi pi-arrow-right"></i>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="box" onClick={() => {
                                            if (isAuth) {
                                                setTopup(true);
                                                navigate("#topup");
                                            } else {
                                                setTopup(true);
                                                navigate("#login");
                                            }
                                        }}>
                                            <div className="card flex align-items-center gap-3">
                                                <div className="icon">
                                                    <i className="pi pi-refresh"></i>
                                                </div>
                                                <div className="text">
                                                    <h2>{redeemVoucher.VOUC010} <br /> {redeemVoucher.VOUC011}</h2>
                                                </div>
                                            </div>
                                            <i className="pi pi-arrow-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>);
};

export default RedeemVoucherBundleDetails;
